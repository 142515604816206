$darkblue:#13111a;
$lightblue:rgba(48, 44, 63, 1);
$lightgray:rgba(230, 230, 230, 1);
$white:#fff; 
$pink:#ff4895;
$discord-icon-color: #EB459E;
$uppercase:uppercase;
$font-md:'thicccboimedium';
$font-sm:'thicccboisemi_bold';
$font-bold:'thicccboibold';
$font:'thicccboiregular';
$font-light:'thicccboilight';
$font-black:'thicccboiblack';
$black-alpha:rgba(0,0,0,.2); 
 

@mixin mix-gredient {
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#13111a+0,13111a+36,5255fc+73,5255fc+73,fd4980+100&0.56+39,0.72+69,0.43+100 */
background: -moz-linear-gradient(top,  rgba(19,17,26,0.56) 0%, rgba(19,17,26,0.56) 36%, rgba(24,23,44,0.3) 39%, rgba(75,78,228,0.1) 69%, rgba(82,85,252,0.1) 73%, rgba(253,73,128,0.1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(19,17,26,0.56) 0%,rgba(19,17,26,0.56) 36%,rgba(24,23,44,0.3) 39%,rgba(75,78,228,0.1) 69%,rgba(82,85,252,0.1) 73%,rgba(253,73,128,0.1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(19,17,26,0.56) 0%,rgba(19,17,26,0.56) 36%,rgba(24,23,44,0.3) 39%,rgba(75,78,228,0.1) 69%,rgba(82,85,252,0.1) 73%,rgba(253,73,128,0.1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8f13111a', endColorstr='#6efd4980',GradientType=0 ); /* IE6-9 */

}
@mixin gredient-style {
   /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#5255fc+0,fd4980+100,fd4980+100 */
background: rgb(82,85,252); /* Old browsers */
background: -moz-linear-gradient(left,  rgba(82,85,252,1) 0%, rgba(253,73,128,1) 100%, rgba(253,73,128,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(82,85,252,1) 0%,rgba(253,73,128,1) 100%,rgba(253,73,128,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(82,85,252,1) 0%,rgba(253,73,128,1) 100%,rgba(253,73,128,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5255fc', endColorstr='#fd4980',GradientType=1 ); /* IE6-9 */
transition: all .5s ease-in-out;
}

@mixin ex-alpha-gredient-style {
   /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#5255fc+0,fd4980+100,fd4980+100 */
background: rgb(82,85,252); /* Old browsers */
background: -moz-linear-gradient(left,  rgba(82,85,252,0.1) 0%, rgba(253,73,128,0.1) 100%, rgba(253,73,128,0.1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(82,85,252,0.1) 0%,rgba(253,73,128,0.1) 100%,rgba(253,73,128,0.1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(82,85,252,0.1) 0%,rgba(253,73,128,0.1) 100%,rgba(253,73,128,0.1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5255fc', endColorstr='#fd4980',GradientType=1 ); /* IE6-9 */
transition: all .5s ease-in-out;
}

@mixin alpha-gredient-style {
   /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#5255fc+0,fd4980+100,fd4980+100 */
background: rgb(82,85,252); /* Old browsers */
background: -moz-linear-gradient(left,  rgba(82,85,252,0.55) 0%, rgba(253,73,128,0.55) 100%, rgba(253,73,128,0.55) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(82,85,252,0.55) 0%,rgba(253,73,128,0.55) 100%,rgba(253,73,128,0.55) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(82,85,252,0.55) 0%,rgba(253,73,128,0.55) 100%,rgba(253,73,128,0.55) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5255fc', endColorstr='#fd4980',GradientType=1 ); /* IE6-9 */
transition: all .5s ease-in-out;
}
 
@mixin gredient-red {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#d31027+0,ea384d+100 */
background: rgb(211,16,39); /* Old browsers */
background: -moz-linear-gradient(left,  rgba(211,16,39,1) 0%, rgba(234,56,77,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(211,16,39,1) 0%,rgba(234,56,77,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(211,16,39,1) 0%,rgba(234,56,77,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d31027', endColorstr='#ea384d',GradientType=1 ); /* IE6-9 */

}
@mixin alpha-gredient-red {
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#d31027+0,ea384d+100 */
background: rgb(211,16,39); /* Old browsers */
background: -moz-linear-gradient(left,  rgba(211,16,39,0.1) 0%, rgba(234,56,77,0.1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(211,16,39,0.1) 0%,rgba(234,56,77,0.1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(211,16,39,0.1) 0%,rgba(234,56,77,0.1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d31027', endColorstr='#ea384d',GradientType=1 ); /* IE6-9 */

}

@mixin gredient-green {
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#11998e+0,38ef7d+100 */
background: rgb(17,153,142); /* Old browsers */
background: -moz-linear-gradient(left,  rgba(17,153,142,1) 0%, rgba(56,239,125,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(17,153,142,1) 0%,rgba(56,239,125,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(17,153,142,1) 0%,rgba(56,239,125,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#11998e', endColorstr='#38ef7d',GradientType=1 ); /* IE6-9 */
}

@mixin alpha-gredient-green {
   /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#11998e+0,38ef7d+100 */
   background: rgb(17,153,142); /* Old browsers */
   background: -moz-linear-gradient(left,  rgba(17,153,142,0.1) 0%, rgba(56,239,125,0.1) 100%); /* FF3.6-15 */
   background: -webkit-linear-gradient(left,  rgba(17,153,142,0.1) 0%,rgba(56,239,125,0.1) 100%); /* Chrome10-25,Safari5.1-6 */
   background: linear-gradient(to right,  rgba(17,153,142,0.1) 0%,rgba(56,239,125,0.1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#11998e', endColorstr='#38ef7d',GradientType=1 ); /* IE6-9 */
   }
 