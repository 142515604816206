@import "../../../assets/scss/variable";
.detail-page-style {
  .col-gap-30 {
    gap: 25px;
  }
  hr {
    border-color: #302c3f;
    opacity: 1;
    margin: 2rem 0;
  }
  h2 {
    font-size: 44px;
    font-family: $font-bold;
  }
  h3 {
    font-size: 26px;
    font-family: $font-bold;
    margin-top: 0px;
  }
  p {
    color: $lightgray;
    margin: 0 0 10px;
    font-size: 16px;
    font-family: "thicccboilight";
  }
  .btn.btn-primary {
    width: auto;
    min-width: 129px;
  }
}
.img-wrapper {
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  background-position: center;
  min-height: 700px;
  background-size: cover;
  background-repeat: no-repeat;
}
.default-box-style {
  border-radius: 20px;
  color: #fff;
  line-height: 1.111em;
  padding: 1px;
  position: relative;
  letter-spacing: 0.01em;
  width: 100%;
  @include alpha-gredient-style;
  transition: all 0.5s ease-in-out;
  .default-bg-style {
    background-color: #13111a;
    border-radius: 20px;
  }
  .inner-default-box.lg-box {
    .image-content-wrap {
      min-height: 112px;
      gap: 15px;
      display: flex;
      align-items: center;
      span {
        font-size: 16px;
      }
      h5 {
        font-size: 26px;
      }
    }
  }

  .inner-default-box {
    display: flex;
    align-items: center;
    @include ex-alpha-gredient-style;
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);
    display: inline-block;
    padding: 0 20px;
    text-align: left;
    width: 100%;
    border-radius: 20px;
    transition: all ease-in-out 0.5s;
    .image-content-wrap {
      min-height: 93px;
      h5 {
        margin: 4px 0 0;
        font-size: 22px;
      }
      span {
        font-size: 14px;
        font-family: "thicccboilight";
      }
    }
    //  &:before {
    //     content: "";
    //     background-color:#13111a;
    //     position: absolute;
    //     height: 100%;
    //     width: 100%;
    //     left: 0;
    //     top: 0;
    //     z-index: -1;
    //     border-radius: 20px; transition: all ease-in-out .5s;
    //  }
    //  &:after {
    //     content: "";
    //     position: absolute;
    //     width: 100%;
    //     background: linear-gradient(to right, rgba(82, 85, 252,0.1) 0%, rgba(253, 73, 128,0.1) 100%, rgba(253, 73, 128,0.1) 100%);
    //     height: 100%;
    //     left: 0;
    //     z-index: -1;
    //     top: 0;
    //     border-radius: 20px; transition: all ease-in-out .5s;
    //  }
  }
}
.sold-total-item-btn {
  display: flex;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  gap: 25px;
}
.discord-details {
  a {
    margin-left: 8px;
    text-decoration: underline;
    display: inline-flex;
    align-items: center;
    color: #fff;
    span {
      white-space: nowrap;
    }
    svg {
      width: 25px;
      height: 25px;
      fill: white;
      margin-left: 8px;
    }
    &:hover {
      color: $discord-icon-color;
      svg {
        fill: $discord-icon-color;
      }
    }
  }
}
@media (max-width: 991px) {
  .img-wrapper {
    margin-bottom: 25px;
    min-height: 400px;
  }
}

@media (max-width: 600px) {
  .image-content-wrap {
    flex-wrap: wrap;
  }
  .img-wrapper {
    margin-bottom: 25px;
    min-height: 300px;
  }
  .sold-total-item-btn {
    flex-wrap: wrap;
  }
}
