.btn.btn-primary {
  position: relative;
  border-radius: 30px;
  background-color: #302c3f;
  color: #fff;
  line-height: 1.111em;
  padding: 1px;
  text-align: center;
  letter-spacing: 0.01em;
  width: 100%;
  @include gredient-style;
  margin: 0px 2px;
  transition: all 0.5s ease-in-out;

  span {
    @include gredient-style;
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);
    display: inline-block;
    padding: 14px 8px;
    width: 100%;
    border-radius: 30px;
    transition: all ease-in-out 0.5s;

    &:before {
      content: "";
      background-color: #302c3f;
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: -1;
      border-radius: 30px;
      transition: all ease-in-out 0.5s;
    }
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      z-index: -1;
      top: 0;
      border-radius: 30px;
      transition: all ease-in-out 0.5s;
      @include ex-alpha-gredient-style;
    }
  }
  &:after {
    content: "";
    @include alpha-gredient-style;
    position: absolute;
    height: 12px;
    opacity: 0.6;
    width: 83%;
    filter: blur(9px);
    left: 50%;
    transform: translateX(-50%);
    bottom: -8px;
  }
  &:hover:after {
    @include gredient-style;
    opacity: 1;
  }
  &:hover {
    span {
      &:after {
        background: linear-gradient(
          to right,
          rgba(82, 85, 252, 1) 0%,
          rgba(253, 73, 128, 1) 100%,
          rgba(253, 73, 128, 1) 100%
        );
        transition: all ease-in-out 0.5s;
      }
    }
  }
}

.btn.btn-default {
  position: relative;
  border-radius: 30px;
  background-color: #302c3f;
  color: #fff;
  border: 0px none;
  line-height: 1.111em;
  padding: 1px;
  padding: 14px;
  text-align: center;
  letter-spacing: 0.01em;
  width: 100%;
  @include gredient-style;
  transition: all 2s linear 1s;
  -webkit-transition: all 2s linear 1s;
  &:hover {
    @include alpha-gredient-style;
    transition: all 2s linear 1s;
    -webkit-transition: all 2s linear 1s;
    position: relative;
  }
  &:after {
    content: "";
    @include gredient-style;
    position: absolute;
    height: 12px;
    width: 83%;
    filter: blur(9px);
    left: 50%;
    transform: translateX(-50%);
    bottom: -8px;
  }
  &:hover::after {
    opacity: 0;
  }
}

.btn.sm-btn-style {
  max-width: 130px;
}

// card style start
.card {
  max-width: 350px;
  min-width: 300px;
  margin: 0px auto;
  border-radius: 30px;
  background-color: #13111a;
  overflow: visible;
  border: 1px solid transparent;
  position: relative;
  &:hover {
    border: 1px solid #00000030;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }
  &:after {
    content: "";
    @include alpha-gredient-style;
    //background-color: #000;
    position: absolute;
    height: 100%;
    border-radius: 30px;
    width: 100%;
    opacity: 0.4;
    filter: blur(2px);
    z-index: -1;
    transition: all 0.5s ease;
  }
  &:hover:after {
    opacity: 0.8;
    filter: blur(15px);
    transition: all 0.5s ease;
  }
  .cardImg-wrapper {
    width: 100%;
    height: 300px;
    overflow: hidden;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }
  .cardImg-wrapper img {
    object-fit: fill;
    height: 300px;
    width: 100%;
    margin: 0px auto;
  }

  .card-body {
    background-color: rgba(48, 44, 63, 1);
    padding: 25px 25px 35px 25px;
    text-align: left;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    min-height: 160px;

    .card-title {
      font-size: 24px;
      font-family: "thicccboisemi_bold";
      color: $white;
    }

    .card-subtitle {
      font-size: 14px;
      font-family: $font-sm;
      color: $lightgray;

      span {
        color: $white;
        font-family: $font-md;
      }
    }

    .btn {
      margin-top: 15px;
    }
  }
}

.mintInavtive {
  pointer-events: none;
}

// card style end
.btn.btn-disabled {
  border-radius: 30px;
  width: 100%;
  border-color: #2b2834;
  background-color: #464254;
  padding: 8px;
  cursor: default;
  color: #dadada;
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.modal-content-custom {
  background-color: rgb(215, 211, 226);
  border-radius: 10px;
}

@media (min-width: 1000px) {
  .main-NFT-style {
    .card {
      min-width: 450px;
      .cardImg-wrapper {
        height: 360px;
        img {
          
          height: 100%;
        }
      }
    }
  }
}
