@import "../../../assets/scss/variable";

header {
  .container-fluid {
    padding: 0;
  }
  .navbar-dark.bg-dark {
    background-color: transparent !important;
  }
  .navbar {
    padding: 18px 0px 4px 0px;
    .navbar-brand {
      max-width: 250px;
      margin: 0;
      transition: ease 0.5s all;
      img {
        width: 100%;
      }
      &:hover {
        transition: ease 0.5s all;
        transform: scale3d(1.03, 1.03, 1.01);
      }
    }
    .navbar-toggler {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 20px;
      padding: 0;
      margin: 0 3px;
      min-height: 15px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      .header-menu-button {
        width: 100%;
        max-height: 1px;
        min-height: 1px;
        padding: 0;
        background-color: #fff;
      }
    }
    .collapse.navbar-collapse {
      justify-content: flex-end;
    }
    .dropdown-toggle {
      &:hover + .dropdown-menu {
        display: block !important;
      }
      &::after {
        display: none;
      }
    }
    .dropdown-menu {
      margin-top: 0px;
      margin-right: 0px;
      margin-left: 0px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: stretch;
      -webkit-align-items: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      padding: 20px 0;
      border-radius: 10px;
      background-color: #6e4fe9;
      background-image: linear-gradient(135deg, #302c3f, #5e5b71);
      box-shadow: 0 15px 30px 0 rgba(119, 96, 219, 0.3);
      &:hover {
        display: block !important;
      }
      .dropdown-item {
        text-transform: $uppercase;
        color: $white;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        margin-top: 0;
        margin-bottom: 0;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        text-decoration: none;
        a {
          display: block;
          margin: 0 !important;
          padding: 10px 80px 10px 30px;
          width: 100%;
          font-size: 14px;
        }
        &:hover {
          background-color: $black-alpha;
          color: $pink;
        }
      }
    }
    .navbar-nav .nav-item .nav-link {
      display: inline-block;
      margin: 0 20px;
      font-size: 14px;
      text-transform: $uppercase;
      font-family: "thicccboimedium";
      letter-spacing: 2px;
      color: $white;
      &:hover {
        color: $pink;
      }
    }
    .navbar-nav .nav-item:last-child {
      padding-right: 49px;
      position: relative;
      a {
        margin-right: 0;
      }
      img {
        position: absolute;
        top: -3px;
        right: -16px;
      }
    }
  }
}
.mobile-nav {
  display: none !important;
}
.navbar-toggler.toggleShow .navbar-toggler-icon {
  background-image: url("../../../assets/images/toggleClose.svg");
  height: 1em;
  width: 1.5em;
  background-size: 18px;
}

.navbar-toggler div {
  position: relative;
  top: 0;
  background-color: #fff;
  transition: 0.3s ease transform, 0.3s ease top, 0.3s ease width,
    0.3s ease right;
  border-radius: 2px;
}
.navbar-toggler div:nth-child(2) {
  margin: 6px 0;
}
.navbar-toggler.toggleShow div:first-child {
  transform: translate3d(0px, 7px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(140deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
}

.navbar-toggler.toggleShow div:last-child {
  transform: translate3d(0px, -7px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(40deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
}

.navbar-toggler.toggleShow div:nth-child(2) {
  opacity: 0;
}

@media (min-width: 991px) {
  header .navbar .navbar-toggler {
    display: none;
  }
  header .navbar li.nav-item {
    margin-right: 32px;
  }
  header .navbar li.nav-item:last-child {
    margin-right: 0px;
  }
  header .navbar li.nav-item > a {
    padding: 20px 0 !important;
  }
  header .navbar .navbar-nav .nav-item:last-child img {
    position: absolute;
    top: -7px;
    right: -16px;
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 990px) {
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: relative;
  }
  .navbar {
    position: inherit;
    text-align: center;
  }
  header .navbar-expand-md .navbar-nav {
    flex-direction: column;
    width: 100%;
  }
  header .navbar-brand,
  header .navbar-toggler {
    position: relative;
    z-index: 999;
  }
  header .navbar .collapse,
  header .navbar .collapse.show.navbar-collapse,
  header .navbar .navbar-collapse {
    justify-content: flex-start;
    position: absolute;
    top:76px;
    text-align: center;
    left: 0;
    width: 100%;
  }
  header .navbar .navbar-collapse .navbar-nav {
    justify-content: flex-start;
    position: absolute;
    min-height: 90vh !important;
    top: -120vh;
    text-align: center;
    left: 0;
    width: 100%;
    background: #13111a;
    z-index: 99;
    transition: ease all 0.5s;
  }
  header .navbar .collapse.show.navbar-collapse .navbar-nav {
    top: 0px;
    transition: ease all 0.5s;
  }
  header .navbar .navbar-nav .nav-item:last-child img {
    right: auto;
    top: 12px;
    width: 80px;
    height: 80px;
  }
  header .navbar .navbar-nav .nav-item {
    padding: 30px 0;
  }
  header .navbar-toggler,
  header .navbar-toggler:hover,
  header .navbar-toggler:focus {
    border: 0px none;
    outline: none;
    box-shadow: none;
  }
  header .navbar .dropdown-menu {
    max-width: 180px;
    margin: 0px auto;
  }
  .mobile-nav {
    display: inline-block !important;
  }
  .desktop-nav {
    display: none !important;
  }
}
@media only screen and (min-width: 767px) and (max-width: 990px) {
  header .navbar {
    padding: 30px 0px 4px 0px;
  }
  header .navbar .navbar-brand {
    max-width: 246px;
  }
}
@media only screen and (min-width: 479px) and (max-width: 766px) {
  header .navbar {
    padding: 24px 0px 4px 0px;
  }
  header .navbar .navbar-brand {
    max-width: 246px;
  }
}
@media (max-width: 478px) {
  header .navbar .navbar-brand {
    max-width: 230px;
  }
  .container .toggle-container .switch-btn .d-flex span {
    font-size: 10px;
  }
  .container .toggle-container .switch-btn .Diconnected {
    padding: 5px 12px 4px 12px;
  }
  .container .toggle-container {
    min-width: 184px;
  }
  header .navbar {
    padding: 18px 0px 4px 0px;
  }
  header .navbar .navbar-brand img {
    width: 190px;
  }
}
