@import "./variable";
@import "./common";

.discordLink {
  color: #fff !important;
}

.discordLink:hover {
  text-decoration: underline;
  color: #EB459E !important;
}

.main-container {
  padding-bottom: 50px;
}
.main-app {
  overflow: hidden;
}
.container {
  max-width: 1246px;
}
a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);
}

p {
  color: $lightgray;
  font-size: 16px;
  font-family: "thicccboilight";
}

body {
  @include mix-gredient;
  background-color: $darkblue;
  margin: 0px;
  color: $white;
  min-height: 100vh;
  font-family: $font-md;
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: rgba(48, 44, 63, 1);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgb(75, 75, 92);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.title-lg {
  font-family: $font-bold;
  font-size: 3rem;
  letter-spacing: 2px;
  font-weight: 700;
  text-transform: capitalize;
  span {
    @include gredient-style;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    font-family: $font-bold;
    font-size: 3rem;
  }
}

.title-sm {
  font-family: $font-bold;
  font-size: 3rem;
  font-weight: 700;
  text-transform: capitalize;
}

.toggle-container {
  position: relative;
  cursor: pointer;
  display: inline-block;
  min-width: unset !important;
  width: auto;
  height: 47px;
  pointer-events: none;
  input {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 100%;
    pointer-events: all;
  }
  input:checked + .switch-btn .Connected {
    display: none;
  }
  .Diconnected {
    display: none;
  }
  input:checked + .switch-btn .Diconnected {
    display: inline-block;
  }
}

.inner-toggle-switch {
  @include gredient-green;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  transition: all ease-in-out 0.5s;
  padding: 1px;
}
input:checked + .switch-btn .inner-toggle-switch {
  @include gredient-red;
}
.connection-btn-text {
  display: flex;
  align-items: center;
  column-gap: 10px;
  line-height: normal;
  flex-wrap: nowrap;

  h5 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    line-height: normal;
  }

  span {
    font-size: 12px;
    color: #fff;
    opacity: 0.8;
  }
}

.Diconnected {
  width: 100%;
  padding: 5px 22px 4px 20px;
  border-radius: 40px;
  position: relative;
  z-index: 9;
  background-color: #302c3f;
  .connection-btn-text {
    h5 {
      @include gredient-red;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }
}

.Connected {
  width: 100%;
  padding: 5px 22px 4px 20px;
  border-radius: 40px;
  position: relative;
  z-index: 9;
  background-color: #302c3f;
  .connection-btn-text {
    h5 {
      @include gredient-green;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }
}

.space-between-flex {
  justify-content: space-between;
  padding: 0px;
  align-items: center;

  .breadcrumb {
    margin: 0;

    a {
      color: rgba(255, 255, 255, 0.6);
    }

    .active.breadcrumb-item {
      color: #fff;
    }
  }
}
.text-bigger {
  font-size: 22px;
  font-weight: 600;
  padding-top: 4em;
}

.main-NFT-style {
  flex-direction: row-reverse;
  .dekstop-hide {
    display: none;
  }
  .title-lg {
    text-transform: none;
  }
}

@media (max-width: 1200px) {
  .container {
    width: 100%;
    max-width: 100% !important;
  }
}

@media (max-width: 767px) {
  .main-container .title-lg,
  .main-container .title-lg span {
    font-size: 2rem;
  }
  .text-bigger {
    font-size: 22px;
    padding-top: 1em;
  }
}
@media (max-width: 768px) {
  .main-NFT-style .dekstop-hide {
    padding-top: 14px;
    display: block;
  }
  .main-NFT-style .mobile-hide {
    display: none;
  }
  .text-bigger {
    padding-top: 0px;
  }
  .title-lg {
    font-size: 2rem;

    span {
      font-size: 2rem;
    }
  }

  .title-sm {
    font-size: 2rem;
  }

  .slick-slider.mt-5 {
    margin-top: 15px !important;
  }

  footer .footer-content-bottom {
    margin-top: 50px;
    margin-bottom: 25px;
    padding-top: 35px;
  }

  .main-container footer {
    margin-top: 10px;
    padding-top: 45px;
  }
}
@media only screen and (min-width: 768px) {
  .main-NFT-style .dekstop-hide {
    display: none !important;
  }
  .main-NFT-style .mobile-hide {
    display: block !important;
  }
  .text-bigger {
    padding-top: 4em;
  }
}
@media (min-width: 980px) {
  .slick-slider .slick-next {
    right: -16px !important;
  }
  .slick-slider .slick-prev {
    left: -16px !important;
  }
}
@media (max-width: 980px) {
  .main-container .detail-page-style h2 {
    font-size: 1.5rem;
  }
  .main-container .detail-page-style h3 {
    font-size: 1.3rem;
  }
  .main-container {
    padding-bottom: 10px;
  }
}

@media only screen and (min-width: 920px) and (max-width: 1200px) {
  .slick-slider .slick-next {
    right: 35px !important;
  }
  .slick-slider .slick-prev {
    left: 35px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1200px) {
  header .navbar .navbar-nav .nav-item .nav-link {
    margin: 0 !important;
  }
  header .navbar .navbar-brand {
    max-width: 218px !important ;
  }
}

@media (max-width: 960px) {
  .NFTcardstyle {
    justify-content: center;
    flex-wrap: wrap;
  }
}

.slick-slider .slick-list {
  padding: 20px 0;
}
.slick-slider .slick-dots li button:before {
  font-size: 9px;
}
.slick-slider .slick-dots li button:before {
  opacity: 0.75;
}
.slick-slider .slick-dots li.slick-active button:before {
  opacity: 1;
  color: rgb(82, 85, 252);
}
.slick-slider .slick-dots li button:hover:before,
.slick-slider .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-slider {
  .slick-next,
  .slick-prev {
    z-index: 9;
  }
  .slick-next {
    right: 16px;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
    opacity: 0.5;
    &:before {
      content: "";
    }
    &:active,
    &:hover,
    &:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    }
  }

  .slick-prev {
    left: 16px;
    opacity: 0.5;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e");
    &:before {
      content: "";
    }
    &:active,
    &:hover,
    &:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e");
    }
  }

  .slick-arrow.slick-disabled {
    background-image: none !important;
  }
}

.underline-text {
  text-decoration: underline !important;
  color: rgba(82, 85, 252, 1);
  &:hover {
    color: palevioletred;
  }
}

.ComingSoonCard {
  #cardImg-wrapper-blur {
    img {
      filter: blur(8px);
      -webkit-filter: blur(8px);
    }
  }
  .card-title {
    margin: 0px auto;
  }
}

.NFTcardstyle {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.sold-nft-section {
  padding: 3rem 15px;
  margin-top: 3rem;
}

@media (max-width: 480px) {
  .main-container .card {
    min-width: 260px;
  }
  .sold-nft-section {
    padding: 0rem 15px;
    margin-top: 2rem;
  }
}

.dual-mint-button {
  display: flex;
  flex-wrap: unset;
  .btn span {
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
