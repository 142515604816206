@import "../../../assets/scss/variable";
.page-not-found {
  width: 100%;
  height: 100%;
  color: #fff;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 40px;
}
.page-not-found .btn {
  max-width: 200px;
  margin-top: 20px;
}
.FOF-text {
  font-size: 5rem;
}
