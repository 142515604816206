@import "../../../assets/scss/variable";
.default-modal-style {
  display: flex;
  padding: 0 15px;
  height: 100%;
  margin: 0 auto;
  margin-left: auto;
  align-items: center;
  justify-content: center;

  h2 {
    @include gredient-style;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    font-family: $font-bold;
    font-size: 34px;
  }

  p {
    color: $lightgray;
    margin: 0;
    font-size: 16px;
    font-family: "thicccboilight";
  }

  .modal-content {
    position: relative;
    background-color: $darkblue;
    border-radius: 30px;

    .modal-body {
      padding: 50px 0px 8px 0px;
    }

    .modal-footer,
    .modal-header {
      border: 0px none;
    }

    .modal-footer {
      flex-wrap: nowrap;
      display: flex;
      padding: 10px 15px 35px;
      justify-content: center;
    }

    .modal-header {
      padding: 24px;

      img {
        position: absolute;
        top: -215px;
        left: 50%;
        transform: translateX(-50%);
      }
      .animationImg {
        max-width: 300px;
      }
      .btn-close {
        background-image: url(../../../assets/images/close.svg);
        position: relative;
        z-index: 99;
      }
    }
  }
}
// modal css end
